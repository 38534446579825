<template>
  <v-row v-bg:b>
    <v-col cols="12">
      <topmenu @modelToggle="modelToggle"/>

      <div class="mt-2" v-if="modelToggleSel =='visualizacao'">
        <encomendasvisualizacao />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='entregas'">
        <encomendasentrega />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='distribuicao'">
        <distribuicao />
      </div>
      <div class="mt-2" v-if="modelToggleSel =='metaquestoes'">
        <v-row>
          <v-col cols="12" md="6" lg="5">
            <carducsquestoes />
          </v-col>
        </v-row>
      </div>
      <div class="mt-2" v-if="modelToggleSel =='ucsmetas'">
        <v-row>
          <v-col cols="12" md="6" lg="5">
            <carducmetas />
          </v-col>
        </v-row>
      </div>
      <div class="mt-2" v-if="modelToggleSel =='ucsprofs'">
        <v-row>
          <v-col cols="12" md="6" lg="5">
            <carducsprofs />
          </v-col>
        </v-row>
      </div>
      <div class="mt-2" v-if="modelToggleSel =='importacao'">
        <importacao />
      </div>

    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "encomendas",
  components: {
    'topmenu': () => import('./topmenu/top-menu.vue'),
    'encomendasvisualizacao': () => import('./acompanhamento/encomendas-visualizacao.vue'),
    'encomendasentrega': () => import('./acompanhamento/encomendas-entrega.vue'),
    'distribuicao': () => import('./distribuicao/ucs.vue'),
    'carducsquestoes': () => import('./cards/card-metas-questoes.vue'),
    'carducmetas': () => import('./cards/card-ucs-metas.vue'),
    'carducsprofs': () => import('./cards/card-ucs-profs.vue'),
    'importacao': () => import('./importacao/importacao.vue'),
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      modelToggleSel: "visualizacao"
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    modelToggle(model) {
      console.log("modelToggle",model);
      this.modelToggleSel = model;
    },

  }
}
</script>

<style scoped>
</style>
